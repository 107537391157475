import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import get from "lodash/get"
import Layout from "../components/layout"
import Hero from "../components/hero"
import ArticlePreview from "../components/article-preview"

const styles = {
  hero: {
    root: {
      justifyContent: "center",
      px: "0px",
    },
    "left-box": {
      width: "256px",
    },
    image: {
      height: { base: "auto", md: "auto", lg: "300px" },
      width: { base: "100%", md: "100%", lg: "500px" },
      ml: { base: "0px", md: "0px", lg: "26px" },
    },
  },
}
class BlogIndex extends React.Component {
  render() {
    const posts = get(this, "props.data.allContentfulBlogPost.nodes")

    return (
      <Layout location={this.props.location} title={"Blog"}>
        <Hero
          title="Blog"
          Image={() => (
            <StaticImage
              src="../images/blog/blog-bg.png"
              placeholder="blurred"
              alt="Blog"
            />
          )}
          content="Announcements and other thoughts from our team"
          styles={styles.hero}
        />
        <ArticlePreview posts={posts} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
