import React from "react"
import PropTypes from "prop-types"
import { Box, Flex } from "@chakra-ui/react"
import FilterBy from "./filter-by"
import Article from "./article"

const styles = {
  root: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    pb: "60px",
  },
  "inner-box": {
    px: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "538px" },
  },
}

const createBlogOptions = () => [
  { id: 1, name: "All Article Types", selected: true },
  { id: 2, name: "Blog", selected: false },
  { id: 3, name: "Webinar", selected: false },
  { id: 4, name: "eBook", selected: false },
  { id: 5, name: "Case Study", selected: false },
]

const createNewsOptions = () => [
  { id: 1, name: "All News Types", selected: true },
  { id: 2, name: "Avant-garde Health News", selected: false },
  { id: 3, name: "Industry News", selected: false },
  { id: 4, name: "Customer News", selected: false },
  { id: 5, name: "Other News", selected: false },
]

const ArticlePreview = ({ posts: _posts, type }) => {
  const [options, setOptions] = React.useState([])
  const handleOnChange = React.useCallback(e => {
    const id = Number(e.target.options[e.target.selectedIndex].value)
    setOptions(prev =>
      prev.map(p => {
        if (p.id === id) return { ...p, selected: true }
        return { ...p, selected: false }
      })
    )
  }, [])
  const selected = React.useMemo(
    () => options.filter(o => o.selected)[0],
    [options]
  )
  const posts = React.useMemo(
    () =>
      selected?.id === 1
        ? _posts
        : _posts?.filter(p => p.tags.includes(selected?.name)),
    [_posts, selected]
  )

  React.useEffect(() => {
    if (type === "news") {
      setOptions(createNewsOptions())
    } else {
      setOptions(createBlogOptions())
    }
  }, [type])

  if (!_posts) return null
  if (!Array.isArray(_posts)) return null

  return (
    <Flex sx={styles.root}>
      <Box sx={styles["inner-box"]}>
        <FilterBy options={options} onChange={handleOnChange} />
        {posts.map(post => (
          <Article
            key={post?.slug}
            slug={post?.slug}
            image={post?.heroImage?.gatsbyImageData}
            title={post?.title}
            description={post?.description?.childMarkdownRemark.html}
            tags={post?.tags}
            date={post?.publishDate}
            type={type}
            link={post?.link?.link}
          />
        ))}
      </Box>
    </Flex>
  )
}

ArticlePreview.propTypes = {
  posts: PropTypes.array,
}

ArticlePreview.defaultProps = {
  posts: [],
  type: "blog",
}

export default ArticlePreview
